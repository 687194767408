import React from 'react';
import Logo  from '../img/header-footer/logo-yire.png';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import MenuIcon from '@material-ui/icons/Menu';
const Header= () =>{
    return(
        <div>
            <Navbar  collapseOnSelect expand="lg" >
            <Container className="header fluid">
            <Navbar.Brand href="/" >
              <img
                alt=""
                src={Logo}
                width="130px"
                height="130px" />
            </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav ">
                    <span><MenuIcon className="menu2" style={{ fontSize: 40 } }></MenuIcon></span>
                </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                </Nav>
                <Nav className="navbar-right">
                <NavDropdown className="down" title="REGLAMENTOS" id="navbarScrollingDropdown">
                        <NavDropdown.Item className="down" href="https://drive.google.com/file/d/1MpLEY3zryOWVDOoYiIYRIgp-mmzfKzLA/view?usp=share_link" target="_blank">Reglamento Interno Convivencia Escolar</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="https://drive.google.com/file/d/1vAZBIcuQlBGaFRREpTza7Wfv8X9eKJZa/view?usp=sharing" target="_blank">Reglamento Interno de Orden Higiene y Seguridad</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="https://drive.google.com/file/d/1dIIxzO8Kdh1pStvMuDROPhdIiPzCYfRL/view?usp=share_link" target="_blank">Reglamento de Evaluación</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="https://drive.google.com/file/d/1vAZBIcuQlBGaFRREpTza7Wfv8X9eKJZa/view?usp=sharing" target="_blank">Plan Integral de Seguridad Escolar</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown className="down" title="NUESTRO COLEGIO" id="navbarScrollingDropdown">
                        <NavDropdown.Item className="down" href="/saludo-directora">Saludo Directora</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="/historia">Historia</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="/misio-y-vision">Misión y Visión</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="/perfil">Perfil del Estudiante</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="/equipo-directivo">Perfil del Docente</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="https://drive.google.com/file/d/1x1OM5iadMRk3MIf50Of9Y_VqIcBhSPGA/view?usp=share_link" target="_blank">Perfil del Apoderado</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="/plan-mejoramiento">Equípo Directivo</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown className="down" title="AREA ACADEMICA" id="navbarScrollingDropdown">
                        <NavDropdown.Item className="down" href="/coordinacion-academica">Coordinación Académica</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="/equipo-academico">Equipo Academico</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="/equipo-docente">Equipo Docente</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="/equipo-pie">Equipo PIE</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown className="down" title="CAPELLANIA Y FORMACION" id="navbarScrollingDropdown">
                        <NavDropdown.Item className="down" href="#action4">Convivencia Escolar</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="#action5">Capellanía</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown className="down" title="CICLOS" id="navbarScrollingDropdown">
                        <NavDropdown.Item className="down" href="#action3">Pre-Básica</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="#action4">Enseñanza Básica</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="#action5">Enseñanza Media</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="#action5">Educación de Adultos</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown className="down" title="COMUNIDAD" id="navbarScrollingDropdown">
                        <NavDropdown.Item className="down" href="#action3">Centro General de Padres</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="#action4">Centro General de Alumnos</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="#action5">Comité Paritario</NavDropdown.Item>
                    </NavDropdown>
                    {/*<NavDropdown className="down" title="AREA DE GESTION" id="navbarScrollingDropdown">
                        <NavDropdown.Item className="down" href="#action3">Descripción</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="#action4">Administración y Finanzas</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="#action5">Horarios de Atención</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="#action4">Prevención de Riesgos</NavDropdown.Item>
                        <NavDropdown.Item className="down" href="#action5">Posta</NavDropdown.Item>
                     </NavDropdown>*/}
                </Nav>
            </Navbar.Collapse>
            </Container>
            </Navbar>
        </div>
    )
}

export default Header;