import React from 'react';
import { Container, Image } from 'react-bootstrap';
import encontru from "../img/encontruccion.png";

const EnContruccion = () => {
    return(
        <Container  >
            <Image src={encontru} fluid />
        </Container>
    )
}
export default EnContruccion;