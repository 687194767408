import React from 'react';
import {Container, Card} from  'react-bootstrap'
import Entrada from "../img/colegio/7.png"
const Comunicados = () => {
    return(
        <Container>
            <Card className=" text-white">
                <Card.Img src={Entrada} alt="Card image" />
                <Card.ImgOverlay>
                    <Card.Title><h1>Comunicados</h1></Card.Title>
                </Card.ImgOverlay>
            </Card>
            <p>Circular Nº
            </p>
                
            <p>
                 Fecha
            </p>
            <p>
                ...
            </p>
        </Container>
    )
}
export default Comunicados;