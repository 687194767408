import React from 'react';
import {Container, Card} from  'react-bootstrap'
import Entrada from "../img/colegio/5.png"
const ProyectoEducativo = () => {
    return(
        <Container>
            <Card className=" text-white">
                <Card.Img src={Entrada} alt="Card image" />
                <Card.ImgOverlay>
                    <Card.Title><h1>Proyecto Educativo Institucional</h1></Card.Title>
                </Card.ImgOverlay>
            </Card>
            <p>Proyecto
            </p>
                
            <p>
                
            </p>
            <p>
              
            </p>
        </Container>
    )
}
export default ProyectoEducativo;