import React from 'react';
import { Container, CardGroup, Card, Button, Col, Row } from 'react-bootstrap';
import imagen1 from "../img/noticias/receso.png"
import imagen2 from "../img/noticias/inicioescolar.png"
import imagen3 from "../img/noticias/paes.png"
import imagen4 from "../img/noticias/navidad.png"
import imagen5 from "../img/noticias/plan lector.png"
const Noticias = () => {
    return(
        <Container >
            <CardGroup  >
                <Row xs={1} md={3} className="g-4">
                    <Col className="fluid-paddin col-especial " >
                        <Card className="h-100">
                        <Card.Img variant="top" src={imagen4} />
                            <Card.Body>
                            <Card.Title>CONCIERTO NAVIDAD 2022 </Card.Title>
                            <Card.Text>
                                <p>El jueves 22 de diciembre del 2022 se llevo a cabo nuestro tradicional programa para recibir la navidad. 
                                 En esta oportunidad  nos acompaño el coro a cargo de la Universidad Adventista de Chile, en el concierto llamado "Es tiempo de Navidad".</p>
                                 <p>Compartimos las imágenes de esta hermosa actividad. </p>
                            </Card.Text>
                            <Button href="https://drive.google.com/drive/folders/1uq-O_il95Q4LdbzPLglAY7mIEHE40Pys?usp=sharing"  target="_blank" variant="primary">FOTOS</Button>{' '}
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">Publicado el 10-01</small>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col className="fluid-paddin col-especial" >
                        <Card className="h-100">
                            <Card.Img variant="top" src={imagen1} />
                            <Card.Body>
                            <Card.Title>RECESO ESCOLAR</Card.Title>
                            <Card.Text>
                                <p>Estimada comunidad, queremos informar que nuestro establecimiento a partir de el miércoles  04 de enero hasta el jueves 23 de febrero tendrá receso escolar y administrativo, por lo que las dependencias se encontraran cerradas para atención al público.
                                    Nuestro canal de información y consultas sobre matrículas del periodo 2023, será nuestro correo electrónico habilitado matriculas@yireliceo.com
                                </p>
                                <p> Corporación Educacional Yire.</p>
                            </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">Publicado el 10-01</small>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col className="fluid-paddin col-especial" >
                        <Card className="h-100">
                            <Card.Img variant="top" src={imagen2} />
                            <Card.Body>
                            <Card.Title>INICIO AÑO ESCOLAR 2023</Card.Title>
                            <Card.Text>
                                <p>Querida comunidad, queremos recordar que el periodo de inicio de clases será el día viernes 03 de marzo a las 08:30 hrs.
                                La Educacíon de Adultos comenzará el lunes 13 de marzo a las 18:00 hrs.</p>
                                <p> Nuestro canal de información y consultas sobre matriculas del periodo 2023, será nuestro correo electrónico habilitado matriculas@yireliceo.com</p>
                            </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">Publicado el 10-01</small>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col className="fluid-paddin col-especial " >
                        <Card className="h-100">
                        <Card.Img variant="top" src={imagen3} />
                            <Card.Body>
                            <Card.Title>Proceso de Admisión 2023</Card.Title>
                            <Card.Text>
                                <p>Acceso a la plataforma de postulación para el sistema de acceso centralizado universitario correspondiente al Proceso de Admisión 2023.</p>
                                <p>Ingresa al link para mas información.</p>
                            </Card.Text>
                            <Button href="https://demre.cl/portales/portal-postulacion"  target="_blank" variant="primary">Link</Button>{' '}
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">Publicado el 10-01</small>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col className="fluid-paddin col-especial " >
                        <Card className="h-100">
                        <Card.Img variant="top" src={imagen5} />
                            <Card.Body>
                            <Card.Title>Plan Lector 2023</Card.Title>
                            <Card.Text>
                                <p>Ya se encuentra disponible para todos nuestros estudiantes, el Plan Lector correspondiente al año 2023.</p>
                                <p>Revisa  los detalles a continuación.</p>
                            </Card.Text>
                            <Button href="https://drive.google.com/file/d/1lCckFk8nzipPvGn8KrBOSPE4GccLoyF4/view?usp=share_link"  target="_blank" variant="primary">Plan Lector</Button>{' '}
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">Publicado el 20-01</small>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </CardGroup>
        </Container>
    )
}
export default Noticias;